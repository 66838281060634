import { useContext } from "react";
import APIServiceContext from "@contexts/APIServiceContext";

function useAPIService() {
  
  const context = useContext(APIServiceContext);
  if (!context)
      throw new Error(
          'No APIServiceContext.Provider found when calling useAPIService.'
      );
  return context;
}

export default useAPIService;
