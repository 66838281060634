import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "@screens/ErrorPage";
import { useState } from 'react'
import { AuthProvider } from "@contexts/AuthContext";
import { APIServiceProvider } from "@contexts/APIServiceContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify';
import DealerSales from "@components/DealerSales";

import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { COGNITO_APP_ID, COGNITO_POOL_ID } from "@constants/urls";
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { NavigationProvider } from "@shared/ContextProviders/NavigationContext";
import {setLogLevel} from "@shared/Utils";
import log from "loglevel";

import './App.css';
import '@aws-amplify/ui-react/styles.css';


const queryClient = new QueryClient()

setLogLevel();

const router = createBrowserRouter([
  {
    path: "/",
    element: <DealerSales />,
    errorElement: <ErrorPage />,
  },
  {
    path: "home",
    element: <DealerSales />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signout",
    element: <SignOut />,
  }
]);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_APP_ID,
      userPoolId: COGNITO_POOL_ID,
    }
  }
});

export function App({ signOut, user }: WithAuthenticatorProps) {

  const [pages] = useState([
    { name: 'Sales', href: 'home', index: 0}
  ]);

  return (
    <div className="App">
      
      <NavigationProvider pages={pages}>
        <AuthProvider>
          <APIServiceProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </APIServiceProvider>
        </AuthProvider>
      </NavigationProvider>

    </div>
  );
}

function SignOut() {

  const navigate = useNavigate();

  try {
    signOut();
    navigate("/");
  } catch (error) {
    log.warn('error signing out: ', error);
  }

  return (<></>);
}

export default withAuthenticator(App, { hideSignUp: true });
