import { formatPrice, isToday  } from "@shared/Utils";
import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";
import {CASH, DEBIT, CREDIT, CONTRA, CREDIT_FEE, DEBIT_FEE} from '@constants/payment_types'
import {GOOD, REFUNDED} from '@constants/status_types'
import dateFormat from "dateformat";
import { FLOORS } from "@constants/floors";

export const COLUMNS = [
  {
    "id": "stallholder",
    "label": "Dealer",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "",
    "format": (cell: any, row: {}, index: number) => { 

      const transaction = row as CompletedTransaction;
      const date = new Date(transaction.timestamp);

      if (!isToday(date))
        return (<>{cell.id}</>);
      return (
        <div className="grid grid-cols-1 w-32"> 
          <div className="">
            {cell.id}
          </div>
          <div className="text-sm">
            {cell.name }
          </div>
        </div>
      );
    }
  },
  {
    "id": "price",
    "label": "Price",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2 py-1 pr-4 sm:text-sm md:text-md lg:text-lg",
    "format": (cell: any, row: {}, index: number) => { 

      const price = formatPrice(cell);

      const transaction = row as CompletedTransaction;
      if (transaction.status === GOOD)
        return price;
      return <div className="line-through">{price}</div>
    }
  },
  {
    "id": "price",
    "label": "Net",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2 py-1 pr-4",
    "format": (cell: any, row: {}, index: number) => { 

      const transaction = row as CompletedTransaction;
      if (transaction.status === GOOD) {
        if (transaction.paymentType === DEBIT)
          return formatPrice(transaction.price - (transaction.price * DEBIT_FEE));
        if (transaction.paymentType === CREDIT)
          return formatPrice(transaction.price - (transaction.price * CREDIT_FEE));
        else
          return formatPrice(transaction.price);
      }
      else if (transaction.status === REFUNDED) {
        if (transaction.paymentType === DEBIT) {
          return (transaction.price * DEBIT_FEE) * 2;
        }
      }
      return <div className="">-</div>
    }
  },
  {
    "id": "description",
    "label": "Description",
    "cellStyle": (row: number) => "py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "py-1",
    "format": (cell: any, row: {}, index: number) => { 
      return (
        <p className="lg:w-48 sm:w-24"> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "stockid",
    "label": "Stock ID",
    "cellStyle": (row: number) =>  "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}) => { 
      return (
        <p className="lg:w-48 sm:w-24"> 
          {cell}
        </p>
      );
    }
  },

  {
    "id": "timestamp",
    "label": "Date",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}, index: number) => { 
      const date = new Date(cell);
      return dateFormat(date, "mmmm dS HH:MM");
      /*
      
      if (isToday(date)) return dateFormat(date, "h:MM");
      return <p className="w-32">{dateFormat(date, "d mmmm")}</p>;
      */
    }
  },
  {
    "id": "floor",
    "label": "Floor",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 
      return FLOORS[cell]
    }
  },
  {
    "id": "till",
    "label": "till",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2  py-1"
  },
  {
    "id": "paymentType",
    "label": "Payment",
    "cellStyle": (row: number) => "pl-2 py-1 sm:text-sm md:text-md lg:text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 
      
      if(cell === CASH)
        return "Cash";
      else if(cell === DEBIT)
        return "Debit";
      else if(cell === CREDIT)
        return "Credit"
      else if(cell === CONTRA)
        return "Contra"
      return "Error: Unknown type"
    }
  },
  {
    "id": "id",
    "label": "Sales ID",
    "cellStyle": (row: number) => "pl-2  py-1 font-medium sm:text-sm md:text-md lg:text-lg text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800",
    "headerStyle": "pl-2  py-1 bg-gray-50 dark:bg-gray-800"
  },
]