import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Bars3Icon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useIsOnline } from 'react-use-is-online';
//import log from "loglevel";
import useNavigation from "@sharedhooks/useNavigation";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface ShellProps {
  pageIndex: number;
  children: JSX.Element;
}

function Shell({ pageIndex, children }: ShellProps) {

  //const {enforce, setEnforce} = useContext(EnforceStockIDsContext);
  //const { isOnline, isOffline, error } = useIsOnline();
  const { isOnline } = useIsOnline();
  const navigation = useNavigation();

  return (
    <>
      <div className="min-h-full">
        <div className="bg-shell-600 pb-32">
          <Disclosure as="nav" className="border-b border-shell-300 border-opacity-25 bg-shell-600 lg:border-none">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-shell-400 lg:border-opacity-25">
                    <div className="flex items-center px-2 lg:px-0">
                      <div className="flex-shrink-0">
                      </div>
                      <div className="ml-10 lg:block">
                        <div className="flex space-x-4">
                          {navigation.pages.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                pageIndex === item.index
                                  ? 'bg-shell-700 text-white'
                                  : 'text-white hover:bg-shell-500 hover:bg-opacity-75',
                                'rounded-md py-2 px-3 text-sm font-medium'
                              )}
                              aria-current={pageIndex === item.index ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                         {isOnline ? <></> : 
                          <div className="ml-6 flex text-white items-center"> 
                            Offline! 
                            <ExclamationTriangleIcon className="ml-6 h-6 w-6"/>
                          </div>
                         
                         }

                        </div>
                      </div>
                    </div>


                    <div className="sm:ml-6 sm:flex sm:items-center">

                    {/* Profile dropdown */}

                      <Menu as="div" className="relative ml-3">

                        <div>
                          <Menu.Button className="relative inline-flex items-center justify-center rounded-md bg-shell-600 p-2 text-shell-200 hover:bg-shell-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-shell-600">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <div>

                            <Menu.Items className="absolute right-0 z-10 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item key="signOut">
                                {({ active }) => (
                                  <a
                                    href={"/signout"}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    Sign out
                                  </a>
                                )}
                              </Menu.Item>
                              <button
                                className="rounded bg-shell-50 px-2 py-1 text-sm font-semibold text-shell-600 shadow-sm hover:bg-shell-100"
                                onClick={() => window.print()}>
                                Print
                              </button>
                            {/** 
                              <Menu.Item key="toggle">
                                
                                <Switch.Group as="div" className=" p-2">
                                  <Switch
                                    checked={enforce}
                                    onChange={setEnforce}
                                    className={`${
                                      enforce ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full `}
                                  >
                                    <span className="sr-only">Force stock ID's</span>
                                    <span
                                      className={`${
                                        enforce ? 'translate-x-6' : 'translate-x-1'
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                  </Switch>
                                  <Switch.Label as="span" className="ml-3 text-sm">
                                    <span className="font-medium text-gray-900">Require stock ID's</span>{' '}
                                  </Switch.Label>
                                </Switch.Group>
                              </Menu.Item>
                            **/}
                            </Menu.Items>

                          </div>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
     
            </div>
          </header>
        </div>
        <main className="-mt-48">
          <div className="h-dvh sm:mx-1 lg:mx-auto max-w-7xl pb-12 sm:px-1 md:px-4 lg:px-8">
            <div className="rounded-lg bg-white py-2 shadow sm:px-4 lg:px-5">
              {children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Shell;
