
import Shell from "@shared/Shell";
import useTransactions from "@hooks/useTransactions";
import useMyDetails from "@hooks/useMyDetails";
import { useEffect, useState } from "react";
import log from "loglevel";
import {Spinner} from "@shared/Spinner";
import {RefreshButton} from "@shared/Buttons";
import {COLUMNS} from "@shared/TransactionTableColumns";
import TransactionsSummary from "@shared/TransactionsSummary";
import dateFormat from "dateformat";

export function DealerSales() {

  const {isLoading, data, refetch} = useMyDetails();

  useEffect(() => {

    if (!DealerView) {
      log.debug("my details:", data);
    }
  
    return () => {
    };
  }, [isLoading, data]);

  return (
    <Shell pageIndex={0}>
      <div>
        {
          isLoading ? 
          <div className="grid justify-items-center">
            <h1 className="text-2xl font-bold">Loading your details...</h1>
            <div className="bg-white text-slate-500 h-32 w-32">
              <Spinner spin={true}/>
            </div> 
          </div>:
          <DealerView lastPayment={data.lastPaymentDateCalculation} name={data.name}/>
        }     
      </div>
    </Shell>
  );
}

type DealerViewProps = {
  lastPayment: string;
  name: string;
};

const DealerView = ({ lastPayment, name }: DealerViewProps) => {

  const {isLoading, data, refetch} = useTransactions(lastPayment);
  const [showSpinner, setShowSpinner] = useState(false);
  const [date] = useState(dateFormat(lastPayment, "mmmm dS"));
  const [columns] = useState([COLUMNS[1], COLUMNS[2], COLUMNS[5], COLUMNS[3], COLUMNS[4]]);
  
  useEffect(() => {

    if (!isLoading) {
      log.debug("rec data:", data);
    }
  
    return () => {
    };
  }, [isLoading, data]);

  async function refresh() {
    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }

  return (
    <div>
    {
      isLoading ? 
      <div className="grid justify-items-center">
        <h1 className="text-2xl font-bold">Loading sales data...</h1>
        <div className="bg-white text-slate-500 h-32 w-32">
          <Spinner spin={true}/>
        </div> 
      </div> :
      
      <div>
        <div className="relative flex items-center justify-between mb-4">
          <h3 className="text-3xl font-bold">
            {name}
          </h3>
        </div>
        <div className="relative flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold">
            Sales since {date}
          </h2>
          <RefreshButton onClick={refresh} showSpinner={showSpinner}/>
        </div>
        <div className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-scroll m-2">
          <TransactionsSummary transactions={data} columns={columns} showContraNet={true}/>
        </div>
      </div>
    }
    </div>
  );
}

export default DealerSales;
