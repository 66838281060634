import { useQuery } from '@tanstack/react-query'
import useAPIService from "@hooks/useAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";


type TransactionsQueryType = (
  start: string,
) => any;

const useTransactions: TransactionsQueryType = (
  start
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useAPIService();

  log.debug("getTransactions invoked", start);
  const end = new Date().toISOString().split("T")[0];

  return useQuery({
    queryKey:["transactions"], 
    queryFn: async () => 
      apiService.fetch("getTransactions", {start: start, end: end})
      .then((data: any) => {
          return processTransactions(data);
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
    })
}

export default useTransactions;
