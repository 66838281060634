import { useQuery } from '@tanstack/react-query'
import useAPIService from "@hooks/useAPIService";
import log from "loglevel";


type DetailsQueryType = (
) => any;

const useMyDetails: DetailsQueryType = (
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useAPIService();

  log.debug("getMyDetails invoked");

  return useQuery({
    queryKey:['details'], 
    queryFn: async () => 
      apiService.fetch("getMyDetails", {})
      .then((data: any) => {
          return data;
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
    })
}

export default useMyDetails;
